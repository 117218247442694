<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'series' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Serie
        </h1>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col class="pt-0 pb-0" cols="6">
            <ValidationProvider name="Descrição" rules="required" v-slot="{ errors }">
              <e-label>Descrição</e-label>
              <v-text-field
                v-on:change="(value) => deixarLetraMinuscula(value, 'descricao')"
                :error-messages="errors"
                v-model="form.descricao"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <ValidationProvider name="Curso" rules="required" v-slot="{ errors }">
              <e-label>Curso</e-label>
              <e-autocomplete
                :items="cursosList"
                :loading="carregandoCursos"
                :item-text="(curso) => curso.descricao"
                :item-value="(curso) => curso.id"
                :error-messages="errors"
                v-model="form.curso_id"
                label="Selecione uma opção"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="12">
            <!-- <ValidationProvider name="Disciplinas" rules="required" v-slot="{ errors }"> -->
            <e-label>Disciplinas</e-label>
            <v-treeview
              selectable
              :items="disciplinasList"
              item-text="descricao"
              v-model="form.disciplinaIdList"
            ></v-treeview>
            <!-- <e-autocomplete
                :items="disciplinasList"
                :loading="carregandoDisciplinas"
                v-model="form.curso_id"
                label="Selecione uma opção"
                solo
                chips
                small-chips
                multiple
              /> -->
            <!-- </ValidationProvider> -->
          </v-col>
          <v-col cols="12">
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              block
              >Enviar Dados</v-btn
            >
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("Cursos", ["cursosList", "carregandoCursos"]),
    ...mapGetters("Disciplinas", ["disciplinasList", "carregandoDisciplinas"]),
    ...mapGetters("Series", ["series"]),
    form() {
      if (!this.series || !this.editing) return {};
      const { serie_id } = this.$route.params;
      const serie = this.series.get(parseInt(serie_id, 10));
      return { ...serie, disciplinaIdList: serie.disciplinas.map((disciplina) => disciplina.id) };
    },
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      submittingForm: false,
    };
  },
  methods: {
    ...mapActions("Cursos", ["loadCursos"]),
    ...mapActions("Series", ["loadSeries"]),
    ...mapActions("Disciplinas", ["loadDisciplinas"]),
    deixarLetraMinuscula(value, campo) {
      // Remover espaços consecutivos (2 ou mais) utilizando expressão regular
      const novo_valor = value
        .replace(/\s{2,}/g, " ")
        .trim()
        .toLowerCase()
        .split(" ");
      const valor = novo_valor
        .map((word) => word.charAt(0).toLowerCase() + word.slice(1))
        .join(" ");
      this.form[campo] = valor;
    },
    async submitForm() {
      this.submittingForm = true;
      try {
        if (this.editing) {
          await this.$services.seriesService.atualizarSerie(this.form);
        } else {
          await this.$services.seriesService.criarSerie(this.form);
        }
        this.$toast.success("Serie Atualizada com sucesso");
        this.$router.push({ name: "series" });
      } catch (error) {
        this.$toast.danger(error.message);
      }
      this.submittingForm = false;
    },
    async loadData() {
      try {
        if (this.series.length === 0) {
          this.$loaderService.open("Carregando dados");
          await Promise.all([this.loadCursos(), this.loadSeries(), this.loadDisciplinas()]);
          this.$loaderService.close();
        } else if (this.cursosList.length === 0 || this.disciplinasList.length === 0) {
          this.$loaderService.open("Carregando dados");
          await Promise.all([this.loadCursos(), this.loadDisciplinas()]);
          this.$loaderService.close();
        }
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style></style>
