<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'gestoesDeAulas' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Vínculo
        </h1>
      </v-col>
    </v-row>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col class="pt-0 pb-0" cols="12">
            <ValidationProvider name="Professor" rules="required" v-slot="{ errors }">
              <e-label>Professor</e-label>
              <e-autocomplete
                :items="professoresList"
                :return-object="false"
                :item-value="(value) => value.id"
                :item-text="(val) => `${val.cpf} - ${val.nome}`"
                :error-messages="errors"
                :loading="carregandoProfessores"
                v-model="form.instrutor_id"
                label="Selecione uma opção"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-row
          class="animate__animated animate__zoomIn"
          style="animation-duration: 0.5s"
          v-for="relacao of relacoes"
          :key="relacoes.indexOf(relacao)"
        >
          <v-col class="pt-0 pb-0" cols="6">
            <ValidationProvider name="Turma" rules="required" v-slot="{ errors }">
              <e-label>Turma</e-label>
              <e-autocomplete
                :items="turmasList"
                :return-object="false"
                :item-value="(value) => value.id"
                :item-text="(val) => `${val.descricao} - (${val.turno.descricao})`"
                :error-messages="relacao.errors ? relacao.errors : errors"
                :loading="carregandoTurmas"
                v-model="relacao.turma_id"
                :disabled="editing"
                label="Selecione uma opção"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>

          <!-- <v-col class="pt-0 pb-0" cols="4" sm="8">
            <e-label>Horarios</e-label>
            <v-select
              v-model="relacao.horarios_ids"
              :items="horariosList"
              :return-object="false"
              :item-value="(value) => value.id"
              :item-text="(value) => value.descricao"
              dense
              solo
              label="Horarios"
              multiple
            ></v-select>
          </v-col> -->

          <v-col class="pt-0 pb-0" cols="6">
            <ValidationProvider name="Disciplina" rules="required" v-slot="{ errors }">
              <e-label>Disciplina</e-label>
              <e-autocomplete
                :items="disciplinasList"
                :return-object="false"
                :item-value="(value) => value.id"
                :item-text="(val) => val.descricao"
                :error-messages="relacao.errors ? relacao.errors : errors"
                :loading="carregandoDisciplinas"
                v-model="relacao.disciplina_id"
                :disabled="editing"
                label="Selecione uma opção"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>

          <v-col class="pt-0 pb-0" cols="4">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <ValidationProvider name="Carga horária" rules="required" v-slot="{ errors }">
                  <e-label>CH SEMANAL</e-label>
                  <v-text-field
                    :error-messages="errors"
                    v-bind="attrs"
                    v-on="on"
                    v-model.number="relacao.carga_horaria"
                    v-mask="'####'"
                    label="20"
                    dense
                    solo
                  />
                </ValidationProvider>
              </template>
              <span>Carga horária semanal do professor</span>
            </v-tooltip>
          </v-col>

          <v-col class="pt-0 pb-0" cols="4">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <ValidationProvider name="Carga anual" rules="required" v-slot="{ errors }">
                  <e-label>CH ANUAL</e-label>
                  <v-text-field
                    :error-messages="errors"
                    v-bind="attrs"
                    v-on="on"
                    v-model.number="relacao.ch_anual_disciplina"
                    v-mask="'####'"
                    label="200"
                    dense
                    solo
                  />
                </ValidationProvider>
              </template>
              <span>Carga horária anual da disciplina</span>
            </v-tooltip>
          </v-col>

          <v-dialog transition="dialog-top-transition" max-width="800">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="green white--text" v-bind="attrs" v-on="on" class="mt-7">
                {{ editing ? "Dias e horários" : "Dias e horários" }}
              </v-btn>
            </template>
            <template>
              <v-card>
                <v-toolbar color="primary" dark>Vinculo de dias e horários</v-toolbar>
                <v-card-text>
                  <v-row class="mt-4">
                    <v-col class="pt-0 pb-0" cols="4">
                      <e-label>Dias da semana</e-label>
                      <v-select
                        v-model="dia_da_semana"
                        :items="diasDaSemana"
                        :return-object="false"
                        :item-value="(value) => value"
                        :item-text="(value) => value.descricao"
                        dense
                        solo
                        label="selecione"
                      ></v-select>
                    </v-col>

                    <v-col class="pt-0 pb-0" cols="4">
                      <e-label>Horarios</e-label>
                      <v-select
                        v-model="horario_selecionado_id"
                        :items="horariosList"
                        :return-object="false"
                        :item-value="(value) => value"
                        :item-text="(value) => value.descricao"
                        dense
                        solo
                        label="selecione"
                      ></v-select>
                    </v-col>

                    <v-col class="pt-7 pb-0" cols="4">
                      <v-btn
                        :disabled="!dia_da_semana || !horario_selecionado_id"
                        color="green"
                        class="white--text"
                        @click="adicionarHorario(relacoes.indexOf(relacao))"
                        >+ adicionar</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-col v-if="!editing">
                  <v-timeline
                    v-show="relacoes.indexOf(relacao) == item.relacao_id"
                    align-top
                    dense
                    v-for="(item, i) in relacoesDiasHorarios"
                    :key="i"
                  >
                    <v-timeline-item
                      ><v-chip class="mt-1" color="green" text-color="white">
                        {{ item.dia.descricao }}
                        | {{ item.horario.descricao }}
                      </v-chip>
                      <v-btn
                        class="ml-2"
                        @click="deleteRelacaoDiaHorario(i, relacoes.indexOf(relacao))"
                        color="error"
                        fab
                        small
                        dark
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-timeline-item>
                  </v-timeline>
                </v-col>

                <v-col v-if="editing">
                  <v-timeline
                    align-top
                    dense
                    v-for="(item, i) in relacoesDiasHorariosEdit"
                    :key="i"
                  >
                    <v-timeline-item>
                      <v-chip class="mt-1" color="green" text-color="white">
                        {{ item.horario.descricao }} | {{ item.dia.descricao }}
                      </v-chip>

                      <v-btn
                        class="ml-2"
                        @click="deleteRelacaoDiaHorario(i, relacoes.indexOf(relacao))"
                        color="error"
                        fab
                        small
                        dark
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-timeline-item>
                  </v-timeline>
                </v-col>
              </v-card>
            </template>
          </v-dialog>

          <v-col class="pt-0 pb-0" cols="1">
            <v-btn
              v-if="relacoes.length > 1"
              class="mt-7"
              color="error"
              @click="() => (relacoes = relacoes.filter((relacaoObj) => relacaoObj !== relacao))"
              :disabled="submittingForm"
              ><v-icon small>fa-trash</v-icon></v-btn
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="d-flex justify-space-between">
            <div class="d-flex">
              <v-btn
                color="primary"
                :disabled="submittingForm"
                :loading="submittingForm"
                type="submit"
                >Enviar Dados</v-btn
              >
              <v-btn
                class="ml-3"
                v-if="!editing"
                color="secondary"
                @click="adicionarRelacao"
                :disabled="submittingForm"
              >
                Adicionar
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.loadData();
  },

  data() {
    return {
      horariosList: [],
      submittingForm: false,
      professoresList: [],
      turmasList: [],
      form: {},
      relacoes: [{}],
      disciplinasList: [],
      carregandoProfessores: false,
      carregandoTurmas: false,
      carregandoDisciplinas: false,

      relacoesDiasHorarios: [],
      relacoesDiasHorariosEdit: [],
      horario_selecionado_id: null,
      dia_da_semana: null,
      diasDaSemana: [
        {
          id: 0,
          descricao: "Segunda",
        },
        {
          id: 1,
          descricao: "Terça",
        },
        {
          id: 2,
          descricao: "Quarta",
        },
        {
          id: 3,
          descricao: "Quinta",
        },
        {
          id: 4,
          descricao: "Sexta",
        },
      ],
    };
  },
  methods: {
    adicionarHorario(relacao) {
      const data = {
        relacao_id: relacao,
        horario: this.horario_selecionado_id,
        dia: this.dia_da_semana,
      };

      if (!this.editing) {
        this.relacoesDiasHorarios.push(data);
      }

      if (this.editing) {
        this.relacoesDiasHorariosEdit.push(data);
      }
    },

    deleteRelacaoDiaHorario(i) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esse vinculo?",
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: false,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando o vinculo");
              try {
                if (!this.editing) {
                  this.relacoesDiasHorarios.splice(i, 1);
                }

                if (this.editing) {
                  this.relacoesDiasHorariosEdit.splice(i, 1);
                }
                this.$toast.success("Vinculo deletado com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },

    adicionarRelacao() {
      const ultimaRelacao = this.relacoes[this.relacoes.length - 1];
      this.relacoes.push({ ...ultimaRelacao });
    },

    async submitForm() {
      this.submittingForm = true;
      try {
        // Editando gestão
        if (this.editing) {
          const formVinculo = {
            ...this.relacoes[0],
            instrutor_id: this.form.instrutor_id,
            relacoesDiasHorarios: this.relacoesDiasHorariosEdit,
          };

          await this.$services.gestoesDeAulasService.atualizar(formVinculo);
          this.$toast.success("Vínculo atualizado com sucesso!");
        }

        // Criando uma nova gestão
        if (!this.editing) {
          const formRelacoes = this.relacoes.map((relacao, index) => ({
            ...relacao,
            instrutor_id: this.form.instrutor_id,
            relacoesDiasHorarios: this.relacoesDiasHorarios.filter((r) => r.relacao_id === index),
          }));

          const responseData = await this.$services.gestoesDeAulasService.criar(formRelacoes);

          this.relacoes = responseData.filter((item) => item.errors);
          this.$toast.success("Vínculo(s) criado(s) com sucesso!");
        }

        if (this.relacoes.length === 0 || this.editing) {
          this.$router.push({ name: "gestoesDeAulas" });
        }
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },

    async loadData() {
      try {
        this.$loaderService.open("Carregando dados");
        this.professoresList = await this.$services.professoresService.syncAll();
        this.turmasList = await this.$services.turmasService.syncAll();
        this.disciplinasList = await this.$services.disciplinasService.syncAll();
        this.horariosList = await this.$services.horariosService.syncAll();

        if (this.editing) {
          const vinculo = await this.$services.gestoesDeAulasService.gestaoGeralId(
            parseInt(this.$route.params.gestaoDeAulasId, 10)
          );

          this.relacoesDiasHorariosEdit = vinculo.relacoesDiasHorarios ?? [];

          if (vinculo) {
            this.form.instrutor_id = vinculo.instrutor_id;
            this.relacoes = [vinculo];
          }
        }
        this.$loaderService.close();
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style></style>
